import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';

/*header*/

import logo from '../assets/images/logo.svg';
import logo1 from '../assets/images/logo-footer.svg';

import menu from '../assets/images/menu.svg';
import close from '../assets/images/close.svg';

import {
  Container,
  Row,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }




  render() {

    return (
      <header className={this.props[0].location.pathname !== '/' ? 'page-header' : ''}>
        <Container>
          <Row>
            <Col lg="2" className="logo">
              <Link to='/'>
                <Isvg src={this.props[0].location.pathname !== '/' ? logo1 : logo} />
              </Link>
            </Col>
            <Col lg="10">
              <ul className="navigation">
                <li><Link to='/'>POČETNA</Link></li>
                <li className={this.props[0].location.pathname == '/stranica/o-nama' ? 'active' : null} ><Link to='/stranica/o-nama'>O NAMA</Link></li>
                <li className={this.props[0].location.pathname == '/stranica/proizvodi' ? 'active' : null}><Link to='/stranica/proizvodi'>PROIZVODI</Link></li>
                <li className={this.props[0].location.pathname == '/stranica/mreza-skladisnih-centara' || this.props[0].location.pathname == '/stranica/distributivni-centar-beograd' ? 'active' : null}>
                  <UncontrolledDropdown>
                    <DropdownToggle caret>
                      SKLADIŠTE I DISTRIBUCIJA
                                            </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem><Link to='/stranica/mreza-skladisnih-centara'> MREŽA SKLADIŠNIH CENTARA</Link></DropdownItem>
                      <DropdownItem><Link to='/stranica/distributivni-centar-beograd'>DISTRIBUTIVNI CENTAR BEOGRAD</Link></DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
                <li className={this.props[0].location.pathname == '/novosti' ? 'active' : null}><Link to='/novosti'>OBAVEŠTENJA</Link></li>

                <li className={this.props[0].location.pathname == '/zaposlenje' ? 'active' : null}><Link to='/zaposlenje'>ZAPOSLENJE</Link></li>
                <li className={this.props[0].location.pathname == '/stranica/sortna-semena-i-hibridi' || this.props[0].location.pathname == '/stranica/mineralna-djubriva'  || this.props[0].location.pathname == '/stranica/pesticidi'  || this.props[0].location.pathname == '/stranica/poljoprivredna-apoteka' ? 'active' : null}>
                  <UncontrolledDropdown>
                    <DropdownToggle caret>
                      SIROVINSKI SEKOTR</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem><Link to='/stranica/sortna-semena-i-hibridi'> SORTNA SEMENA I HIBRIDI</Link></DropdownItem>
                      <DropdownItem><Link to='/stranica/mineralna-djubriva'>MINERALNA ĐUBRIVA</Link></DropdownItem>
                      <DropdownItem><Link to='/stranica/pesticidi'>PESTICIDI</Link></DropdownItem>
                      <DropdownItem><Link to='/stranica/poljoprivredna-apoteka'>POLJOPRIVREDNA APOTEKA</Link></DropdownItem>

                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
                <li className={this.props[0].location.pathname == '/kontakt' ? 'active' : null}><Link to='/kontakt'>KONTAKT</Link></li>
              </ul>
            </Col>
          </Row>
        </Container>

        <button className="hamburger" onClick={() => this.setState({ _menu: true })}>
          <Isvg src={menu} />
        </button>
        {this.state._menu ?
          <div className="navigation-mobile">
            <button className="hamburger" onClick={() => this.setState({ _menu: null })}>
              <Isvg src={close} />
            </button>

            <ul>
              <li className={this.props[0].location.pathname == '/' ? 'active' : null} onClick={() => this.setState({ _menu: null })}><Link to='/'>POČETNA</Link></li>
              <li className={this.props[0].location.pathname == '/stranica/o-nama' ? 'active' : null} onClick={() => this.setState({ _menu: null })}><Link to='/stranica/o-nama'>O NAMA</Link></li>
              <li className={this.props[0].location.pathname == '/stranica/proizvodi' ? 'active' : null} onClick={() => this.setState({ _menu: null })}><Link to='/stranica/proizvodi'>PROIZVODI</Link></li>
              <li>SKLADIŠTE I DISTRIBUCIJA
                                <ul>
                  <li className={this.props[0].location.pathname == '/stranica/mreza-skladisnih-centara' ? 'active' : null} onClick={() => this.setState({ _menu: null })}><Link to='/stranica/mreza-skladisnih-centara'> MREŽA SKLADIŠNIH CENTARA</Link></li>
                  <li className={this.props[0].location.pathname == '/stranica/distributivni-centar-beograd' ? 'active' : null} onClick={() => this.setState({ _menu: null })}><Link to='/stranica/distributivni-centar-beograd'>DISTRIBUTIVNI CENTAR BEOGRAD</Link></li>
                </ul>
              </li>

              <li className={this.props[0].location.pathname == '/novosti' ? 'active' : null} onClick={() => this.setState({ _menu: null })}><Link to='/novosti'>OBAVEŠTENJA</Link></li>
              <li className={this.props[0].location.pathname == '/zaposlenje' ? 'active' : null} onClick={() => this.setState({ _menu: null })}><Link to='/zaposlenje'>ZAPOSLENJE</Link></li>
              <li>SIROVINSKI SEKTOR
              <ul>
                  <li className={this.props[0].location.pathname == '/stranica/sortna-semena-i-hibridi' ? 'active' : null} onClick={() => this.setState({ _menu: null })}><Link to='/stranica/sortna-semena-i-hibridi'> SORTNA SEMENA I HIBRIDI</Link></li>
                  <li className={this.props[0].location.pathname == '/stranica/mineralna-djubriva' ? 'active' : null} onClick={() => this.setState({ _menu: null })}><Link to='/stranica/mineralna-djubriva'>MINERALNA ĐUBRIVA</Link></li>
                  <li className={this.props[0].location.pathname == '/stranica/pesticidi' ? 'active' : null} onClick={() => this.setState({ _menu: null })}><Link to='/stranica/pesticidi'>PESTICIDI</Link></li>
                  <li className={this.props[0].location.pathname == '/stranica/poljoprivredna-apoteka' ? 'active' : null} onClick={() => this.setState({ _menu: null })}><Link to='/stranica/poljoprivredna-apoteka'>POLJOPRIVREDNA APOTEKA</Link></li>
                </ul>


              </li>
              <li className={this.props[0].location.pathname == '/kontakt' ? 'active' : null} onClick={() => this.setState({ _menu: null })}><Link to='/kontakt'>KONTAKT</Link></li>
            </ul>
          </div>
          :
          null
        }

      </header>
    );
  }
}

export default Header;